import React, { useEffect, useState, useRef } from "react";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { Link } from "gatsby";
import Axios from "axios";
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import $ from 'jquery';
import _ from 'lodash';

const LuxuryCare = ({ data }) => {
  const luxuryCareData = data;
  const [activeService, setActiveService] = useState(0);
  const [serviceImage, setServiceImage] = useState();
  const [email, setEmail] = useState('');
  const [emailResponse, setEmailResponse] = useState('');

  const location = useLocation();

  const header_descriptor = luxuryCareData?.allContentfulDescriptor?.nodes?.find((item) => item?.contentful_id === '5uW1H6PYfaDX2mJZHzeJR');
  const problem_descriptor = luxuryCareData?.allContentfulDescriptor?.nodes?.find((item) => item?.contentful_id === '5JllpuyekKjxCJeRhkFqS2');
  const homeConnect = luxuryCareData?.allContentfulDescriptor?.nodes?.find((item) => item?.contentful_id === '5a7QODbQva0voaBO8vDYt7');

  const luxuryCareConnectTitleRaw = JSON.parse(
    homeConnect?.longDescription.raw
  );
  const htmlContent = luxuryCareConnectTitleRaw?.content[0].content.map((item) => (item.value));

  const blogs = luxuryCareData?.allContentfulBlog?.nodes;
  const oderedBlogData = _.orderBy(blogs, [blogs => blogs.title], ['desc'])

  useEffect(() => {
    if (location?.state?.scrollTo) {
      $('html, body').animate({ scrollTop: $('#' + location?.state?.scrollTo).offset().top - 100 }, 'slow');
    } else {
      window.scrollTo(0, 0);
    }
  }, []);

  // luxuryCareConnectTitleRaw
  const changeActiveService = (flag, data) => {
    setServiceImage(isMobile ? data.mobileImage.fluid : data.image.fluid);
    setActiveService(flag);
  };

  useEffect(() => {
    const image =
      luxuryCareData?.allContentfulLuxuryCareServices.nodes[0].image.fluid;
    setServiceImage(image);
  }, []);

  function getWindowDimensions() {
    const isBrowser = () => typeof window !== "undefined"
    if (isBrowser) {
      const { innerWidth: width, innerHeight: height } = window;

      if (width <= 414) {
        return true;
      } else {
        return false
      }
    }
  };

  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    function handleResize() {
      setIsMobile(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const connectNowHandler = (event) => {
    event.preventDefault();

    if (email) {
      const body = {
        "email": email
      };
      Axios.post("https://api.lqam.com/users/send-subscribe-user-request", body)
        .then(response => {
          if (response?.data?.statusCode === 200) {
            setEmailResponse('Your request has been sent successfully');
          }
        })
        .catch(err => {
        })
    }
  };

  return luxuryCareData ? (
    <>
      <Helmet>
        <title>{`Luxury Care & Service`}</title>
      </Helmet>
      <BackgroundImage
        className="banner luxury-care-banner"
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={luxuryCareData?.contentfulHeroHeader?.headerImage?.fluid}
      >
        <div className="container">
          <div className="banner-wrapper">
            <h1 className="heading font-white br-none">
              {luxuryCareData?.contentfulHeroHeader?.headerTitle}
            </h1>
          </div>
        </div>
      </BackgroundImage>
      <section className="come-home bg-gray" id="standards">
        <div className="container">
          <div className="come-home-wrapper">
            <div className="title-wrapper text-left">
              <h2 className="theme-title text-center">
                {
                  header_descriptor
                    ?.title
                }
              </h2>
              <p className="theme-desp text-center">
                {
                  header_descriptor?.description
                }
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="luxury-care-services bg-gray" id="services">
        <div className="container">
          <div className="row bg-white">
            <div className="col-md-6 care-services-list">
              <div className="service-accordion">
                <div className="accordion-container">
                  {luxuryCareData?.allContentfulLuxuryCareServices.nodes.map(
                    (item, index) => {
                      return (
                        <div
                          key={item.title}
                          className={`${activeService === index ? "set active" : "set"
                            }`}
                        >
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => changeActiveService(index, item)}
                          >
                            {item.title}
                            <i
                              className={`fa fa-${activeService === index ? "minus" : "plus"
                                }`}
                            ></i>
                          </a>
                          <div className="content">
                            <p>{item.subTitle}</p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 care-service-banner">
              {serviceImage ? (
                <Img
                  fluid={serviceImage}
                  fadeIn={true}
                  loading="eager"
                  durationFadeIn={1}
                  alt=""
                  style={{
                    width: "100%",
                    maxHeight: "360px",
                    minHeight: "360px",
                    borderRadius: "3px",
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </section>

      <BackgroundImage
        className="luxury-need"
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={isMobile ? luxuryCareData.contentfulLuxuryGuestServices?.mobileImage?.fluid : luxuryCareData.contentfulLuxuryGuestServices?.backgroundImage.fluid}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 left">
              <h2 className="theme-title">
                {
                  luxuryCareData?.contentfulLuxuryGuestServices?.guestServices.title
                }
              </h2>
              <p className="theme-desp">
                {
                  luxuryCareData?.contentfulLuxuryGuestServices?.guestServices.sub_title
                }
              </p>
            </div>
            <div className="col-md-6 right">
              <ul className="luxury-list">
                {luxuryCareData.contentfulLuxuryGuestServices.guestServices.services.map(
                  (item) => {
                    return <li key={item}>{item}</li>;
                  }
                )}
                <li className="read-more">so Much More</li>
              </ul>
            </div>
          </div>
        </div>
      </BackgroundImage>

      <section className="connect-with luxury-care-connect">
        <div className="container">
          <div className="connect-with-wrapper">
            <div className="title-wrapper">
              <h2 className="theme-title text-center">
                {
                  luxuryCareData?.contentfulConnectHeaders
                    ?.title
                }
              </h2>
            </div>
            <form className="connect-from" onSubmit={(e) => connectNowHandler(e)}>
              <div className="form-field">
                <input
                  type="text"
                  placeholder="I want 5-Star Concierge Service"
                  className="form-control"
                  value={email}
                  type="email"
                  onChange={(event) => setEmail(event.target.value)}
                />
                {
                  emailResponse ? <p style={{ marginTop: 4 }}>{emailResponse}</p> : null
                }
              </div>
              <div className="submit-btn">
                <input
                  type="submit"
                  className="theme-button"
                  value="CONNECT NOW"
                />
              </div>
            </form>
          </div>
        </div>
      </section>

      <BackgroundImage
        className="luxury-home-banner"
        id="smartHomeConnection"
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={homeConnect?.image?.fluid}
      >
        <a href="https://app.lqam.com" target="_blank">
          <div
            className="container text-right"
            dangerouslySetInnerHTML={{
              __html: htmlContent.join('')
            }}
          ></div>
        </a>
      </BackgroundImage>

      <BackgroundImage
        className="luxury-guesswork"
        id='maintenance-listing'
        style={{ backgroundAttachment: 'scroll' }}
        Tag="section"
        fluid={luxuryCareData?.contentfulLuxuryCareMaintenance.backgroundImage.fluid}
      >
        <div className="container" id="maintenance-listing">
          <div>
            <h2 className="theme-title text-center">
              {
                luxuryCareData?.contentfulLuxuryCareMaintenance
                  .mainTitle
              }
            </h2>
            <p className="theme-desp text-center">
              {luxuryCareData?.contentfulLuxuryCareMaintenance
                .subTitle.subTitle}
            </p>
            <p className="theme-desp text-center">
              {
                luxuryCareData?.contentfulLuxuryCareMaintenance
                  .maintenanceServices.title
              }
            </p>
          </div>
          <div className="row guess-lists">
            {luxuryCareData?.contentfulLuxuryCareMaintenance.maintenanceServices.maintenance_services.map(
              (item) => {
                return (
                  <div className="col-sm-3">
                    <ul className="luxury-list">
                      <li>{item}</li>
                    </ul>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </BackgroundImage>

      <section className="luxury-problem-listing">
        <div className="container">
          <div className="row">
            <div className="col-md-4 left">
              <div className="title-wrapper">
                <h2 className="theme-title br-none problem-txt">
                  {
                    problem_descriptor?.title
                  }
                </h2>
                <p className="theme-desp">
                  {
                    problem_descriptor?.description
                  }
                </p>
              </div>
            </div>
            <BackgroundImage
              className="col-md-8 banner-block"
              style={{ backgroundAttachment: 'scroll', height: '460px' }}
              Tag="div"
              fluid={isMobile ? problem_descriptor?.mobileImage?.fluid : problem_descriptor?.image?.fluid}
            >
            </BackgroundImage>

          </div>
        </div>
      </section>

      <section
        className="about blog-posts">
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              {oderedBlogData &&
                oderedBlogData?.length > 0 &&
                oderedBlogData?.map(
                  (item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="col-md-6 about-col" id="property">
                          <Link to={`/${item?.redirectLink}/`} >
                            <span className="img">
                              <Img fluid={item?.image?.fluid} alt="Property Care" />
                            </span>
                            <div className="title-wrapper">
                              <h2 className="theme-title">{item?.title}</h2>
                              <p className="theme-desp">{item?.description}</p>
                            </div>
                            <Link to={`/${item?.redirectLink}/`} className="more-link">
                              Learn More
                            </Link>
                          </Link>
                        </div>
                      </React.Fragment>
                    );
                  }
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  ) : (
    <></>
  );
};

export default LuxuryCare;
