import React from "react";
import { graphql } from "gatsby";
import LuxuryCare from "../components/luxury-care";

const LuxuryCarePage = ({ data }) => {
  return (
    <>
      <LuxuryCare data={data} />
    </>
  )
};

export default LuxuryCarePage;

export const query = graphql`
  {
    allContentfulNavigationSliderContent {
      nodes{
        title{
          title
        }
        redirectUrl,
        link
      }
    }
    contentfulConnectHeaders(contentful_id: {eq: "3WbcjUJ639gg9ZXUiBzwD5"}) {
    title
    }
    
    allContentfulDescriptor(
    filter: {contentful_id: {in: ["5uW1H6PYfaDX2mJZHzeJR", "5JllpuyekKjxCJeRhkFqS2", "5a7QODbQva0voaBO8vDYt7"]}}
  ) {
    nodes {
      description
      title
      contentful_id
      image {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
      mobileImage {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
      longDescription {
        raw
      }
    }
    }

    contentfulLuxuryCareMaintenance {
      backgroundImage {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      subTitle{
        subTitle
      }
      mainTitle
      maintenanceServices {
        title
        maintenance_services
      }
    }
     allContentfulLuxuryCareServices(sort: {order: ASC, fields: createdAt}) {
    nodes {
      image {
        fluid(quality: 100, maxWidth: 2000, cornerRadius: 20) {
          ...GatsbyContentfulFluid
        }
      }
      subTitle
      title
      mobileImage {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
   allContentfulBlog(
        filter: {contentful_id: {in: ["4k8zebCNLBqASLqKBEtARg","3xkX7hB8J1XIyX1DowdTrs"]}}
      ) {
        nodes {
          title
          redirectLink
          description
          contentful_id
          image {
            fluid(quality: 100, maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
  }
    contentfulHeroHeader(contentful_id: {eq: "1hL5Bpe2j6pvRhVs4n2abi"}) {
    headerTitle
    headerImage {
      fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyContentfulFluid
        }
    }
  }
   contentfulLuxuryGuestServices(backgroundImage: {fluid: {}}) {
    guestServices {
      services
      sub_title
      title
    }
    backgroundImage {
      fluid(maxWidth: 2000, quality: 100) {
        ...GatsbyContentfulFluid
      }
    }
    mobileImage {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyContentfulFluid
      }
    }
  }
    contentfulCookie {
      cookieText {
       cookieText
      }
    }
  }
`;
